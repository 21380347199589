import type { MetaFunction } from "@remix-run/react";
import type { CSSProperties } from "react";

import {
  BeerBottle,
  Broadcast,
  CaretDown,
  ChatsCircle,
  DeviceMobileCamera,
  Flask,
  Layout,
  Lightbulb,
  LockKey,
  MonitorPlay,
  PiggyBank,
  PlugsConnected,
  Robot,
  ShareNetwork,
  ShoppingCart,
  Speedometer,
  Translate,
  TrendUp,
  UsersFour,
  VideoConference,
} from "@phosphor-icons/react";
import { Link } from "@remix-run/react";
import { useRef } from "react";

import { FlexCol } from "~/components/flex-col";
import { FlexRow } from "~/components/flex-row";
import { EmailCapture } from "~/components/marketing/email-capture";
import { Footer } from "~/components/marketing/footer";
import { GradientVideo } from "~/components/marketing/gradient-video";
import { HowItWorks } from "~/components/marketing/how-it-works";
import { ProductCarousel } from "~/components/marketing/product-carousel";
import { ProductFeatures } from "~/components/marketing/product-features";
import { WhosItFor } from "~/components/marketing/whos-it-for";
import { YCBadge } from "~/components/marketing/yc-badge";
import { buttonVariants } from "~/components/ui/button";
import { Typography } from "~/components/ui/typography";
import { cn } from "~/lib/utils";
import { CLOUDINARY_IMAGE_URL, CLOUDINARY_VIDEO_URL } from "~/utils/constants";
import { metaTitle } from "~/utils/misc";
import { TALK_TO_US_URL } from "~/utils/paths";

export const meta: MetaFunction = () => [
  {
    title: metaTitle(
      "Conveo uses AI to conduct and analyze hundreds of in-depth voice interviews, providing unparalleled customer understanding, instantly.",
    ),
  },
];

// eslint-disable-next-line prefer-smart-quotes/prefer -- intentional
const style = { fontVariationSettings: '"SOFT" 50' } satisfies CSSProperties;

export default function MarketingLandingPage() {
  const productSectionRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <header className="relative m-2 mx-auto min-h-[calc(100vh_-_12px)] w-[calc(100%_-_12px)] max-w-screen-3xl overflow-hidden rounded-3xl sm:mt-5 sm:min-h-[calc(100vh_-_40px)] sm:w-[calc(100%_-_40px)]">
        <GradientVideo
          className="absolute inset-0 -z-10 min-h-full min-w-full object-cover"
          poster={`${CLOUDINARY_IMAGE_URL}/f_jpg/gradients/gradient-header-preview.jpg`}
          src={`${CLOUDINARY_VIDEO_URL}/q_50/gradients/gradient-header.mp4`}
        />
        <FlexCol className="min-h-[calc(100vh-6rem)] px-3 py-32 sm:px-6 sm:pt-48 3xl:px-16">
          <YCBadge className="mb-5 self-start" />
          <Typography
            className="mb-6 max-w-[1200px] font-light tracking-[-1px] text-primary-foreground drop-shadow-lg"
            style={style}
            variant="hero"
          >
            The World’s{" "}
            <span className="font-semibold">
              Most Powerful Qualitative Insights
            </span>{" "}
            Platform
          </Typography>
          <p className="mb-6 max-w-[700px] rounded-3xl border border-white/30 px-3 py-2 text-lg leading-7 text-primary-foreground drop-shadow-lg backdrop-blur">
            Conveo is a qualitative research platform that conducts and analyzes
            in-depth, AI-moderated voice & video interviews. By merging the
            depth of qualitative interviews with the scale of a survey, we
            provide deeper insights, blazingly fast.
          </p>
          <FlexRow className="mb-7 gap-4">
            <Link
              className={cn(
                buttonVariants({ size: "xl" }),
                "text-base font-bold text-white",
              )}
              to={TALK_TO_US_URL}
            >
              See a Demo
            </Link>
            <EmailCapture />
          </FlexRow>
        </FlexCol>
        <div className="absolute bottom-4 flex w-full justify-center">
          <button
            className="flex animate-pulse flex-col items-center text-primary-foreground"
            onClick={() =>
              productSectionRef.current?.scrollIntoView({ behavior: "smooth" })
            }
          >
            Scroll down
            <CaretDown size={32} />
          </button>
        </div>
      </header>
      <FlexCol
        className="mx-auto max-w-7xl items-center justify-center py-24"
        id="product"
        ref={productSectionRef}
      >
        <Typography
          as="h2"
          className="mx-auto max-w-2xl pb-12 text-center text-foreground drop-shadow-lg"
          variant="h1"
        >
          Unleash the Power of AI-Enabled Qualitative Insights
        </Typography>
        <ProductCarousel />
      </FlexCol>
      <div className="mx-auto max-w-7xl px-6 py-32">
        <Typography as="h2" className="pb-6" variant="h1">
          The Next Frontier of Qualitative Insights
        </Typography>
        <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-20">
          <ProductFeatures list={valueProposition} variant="large" />
        </div>
      </div>
      <FlexCol className="mx-auto max-w-5xl items-center px-4 pb-32 sm:px-8">
        <WhosItFor />
      </FlexCol>
      <div
        className="relative mx-auto max-w-screen-3xl overflow-hidden px-6 py-32 3xl:mt-8 3xl:rounded-xl"
        id="use-cases"
      >
        <GradientVideo
          className="pointer-events-none absolute inset-0 -z-10 min-h-full object-cover"
          poster={`${CLOUDINARY_IMAGE_URL}/f_jpg/gradients/gradient-use-cases-preview.jpg`}
          src={`${CLOUDINARY_VIDEO_URL}/q_50/gradients/gradient-use-cases.mp4`}
        />
        <div className="mx-auto max-w-7xl">
          <Typography as="h2" className="mb-16 text-white" variant="h1">
            Use Cases
          </Typography>
          <FlexRow className="grid grid-cols-1 gap-x-4 gap-y-8 align-top sm:grid-cols-2 sm:gap-10 xl:grid-cols-3">
            {useCases.map(({ Icon, description, title }) => (
              <div
                className="rounded-3xl bg-card/80 p-10 drop-shadow-lg backdrop-blur"
                key={title}
              >
                <Icon className="text-primary" size="40" weight="light" />
                <Typography as="h3" className="py-3" variant="h2">
                  {title}
                </Typography>
                <Typography className="max-w-4xl font-normal">
                  {description}
                </Typography>
              </div>
            ))}
          </FlexRow>
        </div>
      </div>
      <div className="bg-muted py-32">
        <div className="mx-auto max-w-7xl sm:px-6">
          <Typography
            as="h2"
            className="mb-16 text-center text-foreground"
            variant="h1"
          >
            How It Works
          </Typography>
          <div className="space-y-20">
            <HowItWorks />
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 py-32" id="features">
        <Typography as="h2" className="mb-16" variant="h1">
          The Complete AI Research Platform
        </Typography>
        <FlexRow className="grid grid-cols-1 gap-x-4 gap-y-8 align-top sm:grid-cols-2 sm:gap-20 xl:grid-cols-3">
          <ProductFeatures list={features} variant="small" />
        </FlexRow>
      </div>
      <Footer />
    </>
  );
}

var valueProposition = [
  {
    Icon: Speedometer,
    description:
      "Conduct and analyze hundreds of video interviews simultaneously, delivering insights instantly. This rapid turnaround accelerates decision-making and keeps your strategy and processes agile.",
    title: "Unprecedented Speed",
  },
  {
    Icon: UsersFour,
    description:
      "Conduct and analyze interviews with large samples, unbound by physical constraints, to deliver robust insights.",
    title: "Massive Scale",
  },
  {
    Icon: PiggyBank,
    description:
      "Achieve high-quality insights at a fraction of the cost through automated moderation, analysis, and reporting. Our platform meets your budget and margin constraints effectively.",
    title: "Cost Efficient",
  },
  {
    Icon: Lightbulb,
    description:
      "Voice and video elicits authentic and in the moment reactions. The power of AI uncovers the unknown in multi-modal unstructured data which traditional methods and human analysis might miss.",
    title: "Human-Like Depth",
  },
];

var useCases = [
  {
    Icon: ShoppingCart,
    description:
      "Uncover what drives your audience and gain a deep understanding of attitudes, needs, category usage, consumption behaviors and customer or shopper journeys.",
    title: "Consumer Behaviour Understanding",
  },
  {
    Icon: Flask,
    description:
      "Generate and test ideas through rapid feedback and iterative improvements for products and services or packaging.",
    title: "Innovation and Product Research",
  },
  {
    Icon: Layout,
    description:
      "Optimize every product interaction through task-based usability and UX testing.",
    title: "Usability & UX Testing",
  },
  {
    Icon: BeerBottle,
    description:
      "Perform data-driven brand positioning, equity, pricing power and loyalty studies to strengthen your brand.",
    title: "Brand Building",
  },
  {
    Icon: Broadcast,
    description:
      "Maximize the impact of your marketing communications with precise emotional and rational feedback about brand assets, advertising, brand activation as well as messaging.",
    title: "Communication & Creative Design",
  },
  {
    Icon: TrendUp,
    description:
      "Elicit critical incidents and uncover the reasons behind touchpoint performance, NPS, and customer satisfaction  to enhance customer support and service.",
    title: "Customer Experience Improvement",
  },
];

var features = [
  {
    Icon: Robot,
    description:
      "Generate the perfect interview guide with our AI market research copilot.",
    title: "AI Survey Design",
  },
  {
    Icon: VideoConference,
    description:
      "Let our AI interviewer conduct voice or video interviews with your target audience.",
    title: "AI Voice & Video Interviews",
  },
  {
    Icon: ChatsCircle,
    description:
      "Let our AI interviewer probe deeper to uncover the most valuable insights.",
    title: "Human-Like Conversations",
  },
  {
    Icon: Translate,
    description:
      "Run interviews in over 50 languages, we’ll translate everything into your language.",
    title: "Multilingual Support",
  },
  {
    Icon: PlugsConnected,
    description:
      "Instantly have meaningful conversations with consumers all over the world.",
    title: "Consumer Panel Integration",
  },
  {
    Icon: MonitorPlay,
    description:
      "Let respondents react to concepts by uploading images, videos, or clickable mockups.",
    title: "Multimedia Support",
  },
  {
    Icon: DeviceMobileCamera,
    description:
      "Participants can complete interviews on the go and perform tasks when not behind their desk.",
    title: "Mobile-first",
  },
  {
    Icon: ShareNetwork,
    description:
      "Conveo prepares everything in various formats, enabling you to easily socialize the insights.",
    title: "Share and Socialize",
  },
  {
    Icon: LockKey,
    description: "Our platform implements best-in-class security controls.",
    title: "Industry-leading security",
  },
];

import yc from "~/images/yc.svg";
import { cn } from "~/lib/utils";

import { FlexRow } from "../flex-row";

type Props = { className?: string };

export function YCBadge({ className }: Props) {
  return (
    <FlexRow
      className={cn(
        "items-center rounded-xl border border-primary-foreground/30 bg-background/20 px-3 py-1.5 font-semibold text-primary-foreground drop-shadow backdrop-blur",
        className,
      )}
    >
      <span className="mr-2.5 font-serif font-normal italic">Backed by</span>
      <img alt="YC logo" className="mr-1.5 h-5 w-auto" src={yc} />
      <span className="text-white">Combinator</span>
    </FlexRow>
  );
}

import { CLOUDINARY_IMAGE_URL, CLOUDINARY_VIDEO_URL } from "~/utils/constants";

import { FlexCol } from "../flex-col";
import { Typography } from "../ui/typography";
import { GradientVideo } from "./gradient-video";

const data = [
  {
    description:
      "Insights teams, innovation or R&D departments, CX teams, and product teams can equip themselves with our advanced AI capability for self-conducted research to drive impactful insights and innovation.",
    poster: `${CLOUDINARY_IMAGE_URL}/f_jpg/gradients/gradient-whos-it-for-in-house-teams-preview.jpg`,
    src: `${CLOUDINARY_VIDEO_URL}/q_50/gradients/gradient-whos-it-for-in-house-teams.mp4`,
    title: "In-House Teams",
  },
  {
    description:
      "Market research companies, creative and innovation agencies, and consulting firms can leverage our cutting-edge technology to deliver superior services to clients.",
    poster: `${CLOUDINARY_IMAGE_URL}/f_jpg/gradients/gradient-whos-it-for-agencies-preview.jpg`,
    src: `${CLOUDINARY_VIDEO_URL}/q_50/gradients/gradient-whos-it-for-agencies.mp4`,
    title: "Agencies",
  },
];

export const WhosItFor = () => (
  <>
    <Typography
      as="h2"
      className="mb-16 text-center text-foreground"
      variant="h1"
    >
      Who’s It For
    </Typography>
    <div className="flex max-w-3xl flex-col gap-8 lg:max-w-none lg:flex-row">
      {data.map(({ description, poster, src, title }) => (
        <Card
          description={description}
          key={title}
          poster={poster}
          src={src}
          title={title}
        />
      ))}
    </div>
  </>
);

type Props = {
  description: string;
  poster: string;
  src: string;
  title: string;
};

function Card({ description, poster, src, title }: Props) {
  return (
    <FlexCol className="relative z-10 h-[450px] justify-between overflow-hidden rounded-3xl lg:w-1/2">
      <GradientVideo
        className="pointer-events-none absolute inset-0 -z-10 min-h-full object-cover"
        poster={poster}
        src={src}
      />
      <div className="px-4 py-6">
        <Typography as="h3" className="mb-4 text-4xl text-white" variant="h2">
          {title}
        </Typography>
      </div>
      <Typography className="flex min-h-32 border-t border-t-white/30 p-4 leading-snug text-white backdrop-blur">
        {description}
      </Typography>
    </FlexCol>
  );
}

import type { Icon } from "@phosphor-icons/react";

import { Typography } from "../ui/typography";

type Props = {
  list: { Icon: Icon; description: string; title: string }[];
  variant: "large" | "small";
};

export function ProductFeatures({ list, variant }: Props) {
  return list.map(({ Icon, description, title }) => (
    <div key={title}>
      <Icon
        className="text-primary"
        size={variant === "small" ? 40 : 60}
        weight="light"
      />
      <Typography
        as="h3"
        className="py-3"
        variant={variant === "small" ? "h2" : "h3"}
      >
        {title}
      </Typography>
      <Typography
        className="max-w-4xl font-normal text-muted-foreground"
        variant="largeText"
      >
        {description}
      </Typography>
    </div>
  ));
}

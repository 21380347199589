import { useIntersectionObserver, useIntervalEffect } from "@react-hookz/web";
import { Fragment, useCallback, useRef, useState } from "react";
import { flushSync } from "react-dom";

import { cn } from "~/lib/utils";
import { CLOUDINARY_IMAGE_URL, CLOUDINARY_VIDEO_URL } from "~/utils/constants";

import { FlexCol } from "../flex-col";
import { Separator } from "../ui/separator";
import { GradientVideo } from "./gradient-video";

const howItWorks = [
  {
    color: "bg-green-300",
    description:
      "Upload your interview guide or state your research objective and let our AI research copilot create an interview guide for you.",
    gradient: `${CLOUDINARY_IMAGE_URL}/f_jpg/gradients/how-it-works-1.jpg`,
    title: "Design Your Study",
    video: {
      placeholderUrl: `${CLOUDINARY_VIDEO_URL}/c_limit,h_700,so_0,w_1300/v1721438675/product-videos/etaydgjafs2fas9uhvlw.jpg`,
      url: `${CLOUDINARY_VIDEO_URL}/f_auto:video,q_auto/v1/product-videos/etaydgjafs2fas9uhvlw`,
    },
  },
  {
    color: "bg-red-300",
    description:
      "Bring your own respondents or recruit your panel within Conveo.",
    gradient: `${CLOUDINARY_IMAGE_URL}/f_jpg/gradients/how-it-works-2.jpg`,
    title: "Share with Respondents",
    video: {
      placeholderUrl: `${CLOUDINARY_VIDEO_URL}/c_limit,h_700,so_0,w_1300/v1721436897/product-videos/jcgmtc4olbjsklzdc43n.jpg`,
      url: `${CLOUDINARY_VIDEO_URL}/f_auto:video,q_auto/v1/product-videos/jcgmtc4olbjsklzdc43n`,
    },
  },
  {
    color: "bg-blue-300",
    description:
      "Let our conversational AI interviewer moderate hundreds of voice and video conversations for you.",
    gradient: `${CLOUDINARY_IMAGE_URL}/f_jpg/gradients/how-it-works-3.jpg`,
    title: "Engage with Users",
    video: {
      placeholderUrl: `${CLOUDINARY_VIDEO_URL}/c_limit,h_700,so_0,w_1300/v1721578878/product-videos/ksxtw8n53pcbwr3p1f58.jpg`,
      url: `${CLOUDINARY_VIDEO_URL}/f_auto:video,q_auto/v1/product-videos/ksxtw8n53pcbwr3p1f58`,
    },
  },
  {
    color: "bg-orange-300",
    description:
      "Watch the AI interviewer dig deep with participants, analyze it, and translate it into actionable insights for you.",
    gradient: `${CLOUDINARY_IMAGE_URL}/f_jpg/gradients/how-it-works-4.jpg`,
    title: "Get Actionable Insights",
    video: {
      placeholderUrl: `${CLOUDINARY_VIDEO_URL}/c_limit,h_700,w_1300/v1721575124/product-videos/luvfjyquzupygq50tj9z.jpg`,
      url: `${CLOUDINARY_VIDEO_URL}/f_auto:video,q_auto/v1/product-videos/luvfjyquzupygq50tj9z`,
    },
  },
];

export function HowItWorks() {
  const [step, setStep] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { isIntersecting = false } =
    useIntersectionObserver(containerRef) ?? {};

  const videoRef = useRef<HTMLVideoElement>(null);

  const goToStep = useCallback(() => {
    flushSync(() =>
      setStep((currentStep) => (currentStep + 1) % howItWorks.length),
    );
    videoRef.current?.load();
    void videoRef.current?.play();
  }, []);

  useIntervalEffect(goToStep, isIntersecting && !isPaused ? 3000 : undefined);

  const handleMouseOut = useCallback(() => setIsPaused(false), []);
  const handleMouseOver = useCallback(() => setIsPaused(true), []);

  const activeItem = howItWorks[step];
  if (activeItem == null) {
    return null;
  }

  return (
    <div className="rounded-3xl bg-card p-6">
      <div className="flex flex-col space-y-4 xl:flex-row xl:space-x-4 xl:space-y-0">
        <FlexCol
          className="max-w-full items-start xl:max-w-md"
          onMouseOut={handleMouseOut}
          onMouseOver={handleMouseOver}
        >
          {howItWorks.map(({ color, description, title }, index) => (
            <Fragment key={title}>
              {index > 0 && (
                <Separator className="my-5" orientation="horizontal" />
              )}
              <button
                className="flex flex-col gap-1"
                onClick={() => setStep(index)}
                onMouseOver={() => setStep(index)}
              >
                <LargeBadge
                  color={color}
                  isActive={index === step}
                  order={index + 1}
                  title={title}
                />
                <div className="px-9 text-left">{description}</div>
              </button>
            </Fragment>
          ))}
        </FlexCol>
        <div className="grow">
          <div className="relative flex h-[600px] w-full items-center justify-around overflow-hidden rounded-xl bg-cover bg-center bg-no-repeat px-4">
            <img
              alt=""
              className="pointer-events-none absolute inset-0 min-h-full"
              src={activeItem.gradient}
            />
            <div
              className="rounded-2xl bg-secondary/80 p-3 drop-shadow-xl"
              ref={containerRef}
            >
              <GradientVideo
                className="rounded-2xl"
                height={350}
                key={activeItem.title} // ensures the video element updates on `goToStep`
                poster={activeItem.video.placeholderUrl}
                ref={videoRef}
                width={600}
              >
                <source src={activeItem.video.url} type="video/mp4" />
              </GradientVideo>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type Props = { color: string; isActive: boolean; order: number; title: string };

function LargeBadge({ color, isActive, order, title }: Props) {
  return (
    <div
      className={cn(
        isActive ? `${color} text-[hsl(222_84%_4.9%)]` : "text-foreground",
        "flex items-center rounded-full py-1 pl-2 pr-4 font-serif text-xl font-semibold",
      )}
    >
      <span
        className={cn("ml-1.5 mr-2 tabular-nums", !isActive && "invisible")}
      >
        {order}
      </span>
      {title}
    </div>
  );
}

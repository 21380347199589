import { useEffect, useState } from "react";

import { CLOUDINARY_VIDEO_URL } from "~/utils/constants";

import type { CarouselApi } from "../ui/carousel";

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";
import { GradientVideo } from "./gradient-video";

const slides = [
  {
    placeholderUrl: `${CLOUDINARY_VIDEO_URL}/c_limit,h_700,so_0,w_1300/v1721578878/product-videos/ksxtw8n53pcbwr3p1f58.jpg`,
    title: "AI-moderated Interviews",
    videoUrl: `${CLOUDINARY_VIDEO_URL}/f_auto:video,q_auto/v1/product-videos/ksxtw8n53pcbwr3p1f58`,
  },
  {
    placeholderUrl: `${CLOUDINARY_VIDEO_URL}/c_limit,h_700,w_1300/v1721575124/product-videos/luvfjyquzupygq50tj9z.jpg`,
    title: "Insights Extraction",
    videoUrl: `${CLOUDINARY_VIDEO_URL}/f_auto:video,q_auto/v1/product-videos/luvfjyquzupygq50tj9z`,
  },
  {
    placeholderUrl: `${CLOUDINARY_VIDEO_URL}/c_limit,h_700,so_0,w_1300/v1721438675/product-videos/etaydgjafs2fas9uhvlw.jpg`,
    title: "Seamless Setup",
    videoUrl: `${CLOUDINARY_VIDEO_URL}/f_auto:video,q_auto/v1/product-videos/etaydgjafs2fas9uhvlw`,
  },
  {
    placeholderUrl: `${CLOUDINARY_VIDEO_URL}/c_limit,h_700,so_0,w_1300/v1721436897/product-videos/jcgmtc4olbjsklzdc43n.jpg`,
    title: "Instant Recruitment",
    videoUrl: `${CLOUDINARY_VIDEO_URL}/f_auto:video,q_auto/v1/product-videos/jcgmtc4olbjsklzdc43n`,
  },
];

const opts = { duration: 18, loop: true };

export function ProductCarousel() {
  const { current, setApi } = useCarousel();

  return (
    <>
      <div className="mb-4 rounded-lg bg-primary/20 px-4 py-2 font-bold backdrop-blur">
        {slides[current - 1]?.title}
      </div>
      <div className="w-full select-none px-8 drop-shadow md:px-24">
        <Carousel opts={opts} setApi={setApi}>
          <CarouselContent className="flex items-center">
            {slides.map((slide, index) => (
              <CarouselItem className="flex flex-col items-center" key={index}>
                <div className="rounded-2xl bg-secondary/80 p-3 backdrop-blur">
                  <GradientVideo
                    className="h-auto w-full rounded-2xl"
                    poster={slide.placeholderUrl}
                  >
                    <source src={slide.videoUrl} type="video/mp4" />
                  </GradientVideo>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </div>
    </>
  );
}

function useCarousel() {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (api == null) {
      return;
    }
    setCurrent(api.selectedScrollSnap() + 1);
    api.on("select", () => setCurrent(api.selectedScrollSnap() + 1));
  }, [api]);

  return { current, setApi };
}
